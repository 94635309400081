<template>
  <v-app>
    
    <v-app-bar color="primary" app dark>
      <!-- <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon> -->

      <v-toolbar-title v-text="$route.name"></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click.stop="signout()" text v-if="isAuthenticated">Log out</v-btn>
      <v-btn v-else text @click="signIn()">Signin</v-btn>
    </v-app-bar>
    <!-- <v-navigation-drawer v-model="drawer" absolute temporary app>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item to="/profile" v-if="isAuthenticated">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Account</v-list-item-title>
          </v-list-item>
          <v-list-item to="/labels">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Labels</v-list-item-title>
          </v-list-item>
          <v-list-item to="/commissions" v-if="isAuthenticated">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Order Entry</v-list-item-title>
          </v-list-item>
          <v-list-item v-on:click.stop="logOut()" v-if="isAuthenticated">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Log Out</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer> -->

    <v-main>
      <v-alert
      app
      dense
      v-model="loadingItems"
      border="left"
      type="warning"
    >
      Inventory is loading.
    </v-alert>
      <router-view :inventory-loading="loadingItems" v-if="isAuthenticated"></router-view>
      <v-container v-else>
        <v-row>
          <v-btn color="primary" @click="GetNewToken">Test</v-btn>
        </v-row>
      </v-container>
    </v-main>
    <vue-snotify></vue-snotify>
    <v-snackbar
      v-model="updateExists"
      :timeout="timeout"
      left
      bottom
    >
      New version available!
      <v-spacer />
      <v-btn dark color="primary" @click.native="refreshApp">Refresh</v-btn>
      <v-btn icon @click="updateExists = false">
        <v-icon color="white" v-text="icons.mdiClose"></v-icon>
      </v-btn>
    </v-snackbar>
    <v-footer app>
      <v-col
      class="text-center"
      cols="12"
    >{{buildNo}} {{myDisplaySize}}
    </v-col></v-footer>
  </v-app>
</template>

<script>
// import authentication from "./authentication";
import { initJsStore,dropJsStore } from "@/service/idb_service";
import { CommissionService } from "@/service/commission_service";
import {mapGetters, mapActions, mapMutations} from "vuex";
import { mapFields } from 'vuex-map-fields';
import {mdiClose} from "@mdi/js"
// import { msalMixin } from 'vue-msal';
import { DateTime } from "luxon";

function setupRayGunUser(user) {
  
  if (user !== null && user.userName) {
    rg4js('setUser', {
      identifier: user.accountIdentifier,
      isAnonymous: false,
      email: user.userName.toLowerCase(),
      fullName: user.name,
    })
  } else {
    rg4js('endSession');

rg4js('setUser', {
  isAnonymous: true
});
  }
}

export default {
  name: "App",
  // mixins: [msalMixin],
  components: {},

  data: () => ({
    drawer: false,
    group: null,
    commService:null,
    registration: null,
    icons:{
      mdiClose
    },
    isDbCreated:false,
    loadingItems:null,
    updateExists: false,
    timeout: -1,
    itemTimer:null,
    buildNo:'1560'
  }),
  watch: {
    user:{
      handler:function(v) {
      console.log('User Set',v)
      if (v.name) {

      } else {
        if(this.itemTimer) clearInterval(this.itemTimer)
      }

      setupRayGunUser(v)
    },
    deep:true
    },
    dbName(v) {
      initJsStore(v)
        .then(res=>
          this.isDbCreated=true
        )
        .catch(err=>{
          console.error(err)
              this.$raygun('send',{error:err})
        })
    },
    // idToken(v) {
    //   this.$http.defaults.headers.common["Authorization"] ="Bearer " + v;
    // },
    isDbCreated(v) {
      this.StartItemSync()
    }
  },
  computed: {
    ...mapGetters("User",["currentUser","axiosHeaders","isAuthenticated"]),
    ...mapFields("User",["idToken"]),
    // user() {
    //       let user = null;
    //       if (this.msal.isAuthenticated) { // Note that the dollar sign ($) is missing from this.msal
    //         user = {
    //           ...this.msal.user,
    //           profile: {}
    //         }
    //         if (this.msal.graph && this.msal.graph.profile) {
    //             user.profile = this.msal.graph.profile
    //         }
    //       }
    //       return user;
    //     },
    accounts: function () {
      return this.$msal.getAllAccounts()
    },
    isAuthed() {
    return this.$msal.isAuthenticated;
  },
        myDisplaySize() {
      return this.$vuetify.breakpoint.name
    },
    // IDToken() {
    //   if (this.$_.isString(this.msal.idToken) && this.msal.idToken.length>20) return this.msal.idToken
    //       return this.idToken;
    // },
    dbName() {
      return this.currentUser

    }
  },
  methods: {
    ...mapActions("User",["signout","refreshToken","signIn"]),
    ...mapMutations("User",["authenticated"]),
  //   async signIn() {
  //   await this.$msal.signIn();
  // },
  resetDb() {
    var that=this;
    dropJsStore(this.dbName)
    .then(()=>{
      window.reload(true);
    })
    .catch(err=>that.$sendError(err))
  },
    logOutClicked() {
      //this.logout();
      this.$router.replace("/Login");
      // authentication.signOut();
    },
    async GetNewToken() {
      let accessToken = await this.signIn();
      console.log(accessToken)  
    },
    StartItemSync() {
      if(this.itemTimer) clearInterval(this.itemTimer)
      if(this.isAuthenticated && this.isDbCreated)
      {
        if(!this.commService) this.commService=new CommissionService();
        if(this.loadingItems==null) this.loadingItems=true;

        this.LoadItems(0);
      } else {
        this.itemTimer=setInterval(this.StartItemSync,15*60*1000)
      }
    },
    LoadItems(pg) {
      var that=this;  
        this.$http
        .get(`https://api.hockenbergs.com/api/VwItemList/Items/${pg}/500`,this.axiosHeaders)
        .then((resp) => {
          if(resp.data.length>0) {
            that.commService.SaveInventory(resp.data)
              pg++
              that.LoadItems(pg)
          }
          else {
            that.loadingItems=false
            if(that.user)
            that.itemTimer=setInterval(that.StartItemSync,3600000)
          }
        })
        .catch((err) => {
          that.loadingItems=false
          if(err.response.status==401 && err.config.headers.Authorization) {
                  msal.acquireTokenSilent({scopes:["user.read"],account:msal.account,forceRefresh:true})
                      .then(r=>this.authenticated(r))
                      .catch(e=>this.$sendError(e));
                } else { 
          
          that.$sendError(err)
          if(that.isAuthenticated)
            that.itemTimer=setInterval(that.StartItemSync,15*60*1000)
        }});
      },
   
    showRefreshUI(e) {
      this.registration = e.detail
      this.updateExists = true
    },
    refreshApp() {
      this.updateExists = false
      if (!this.registration || !this.registration.waiting) {
        return
      }
      this.registration.waiting.postMessage('skipWaiting')
    },
  },
  mounted() {
    // if(this.isAuthenticated) {
    //   this.$http.defaults.headers.common["Authorization"] ="Bearer " + this.idToken;
    //   setupRayGunUser(this.user);
    // }

    if(this.dbName) {
      var that=this;
      initJsStore(this.dbName)
    .then(res=>{
      that.isDbCreated=true
    }
    )
    .catch(err=>{
          this.$raygun('send',{error:err})
    })
    }
    
      // this.StartItemSync();
  },
  created() {
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true })
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return
        this.refreshing = true
        window.location.reload()
      })
    }
  },
  destroyed() {
    document.removeEventListener('swUpdated', this.showRefreshUI)
    clearInterval(this.itemTimer)
  }
};
</script>

<style lang="scss">
@import "~vue-snotify/styles/material";
</style>