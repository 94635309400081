import axios from 'axios'
import _ from 'underscore'
import { getField, updateField } from 'vuex-map-fields';

const state = {
    customerNum:null,
    shipTo:null,
    shipVia:'BEST WAY',
      items:[],
      pmtMethod:'House'
}

const getters = {
    Order(state) {
        return {
                "orderId": null,
      "customer_num": state.customerNum,
      "ship_to": state.shipTo,
      "ship_via": state.shipVia,
      "pmt_method":state.pmtMethod,
      "orderDetails": state.items.map(i=>{
          return {
            "item_num": i.item_num,
            "description_1": i.description_1,
            "order_flag": i.orderFlag=='W' ? 0 : 1,
            "amt_ordered": i.qty,
            "price": i.sellingPrice,
          }
      })
        }
    },
    TotalComm:state=>state.items.reduce((t,i)=>{
        var comm=_.find(i.comms,c=>i.sellingPrice>=c.s_price)
      
        return t+(comm.pct*(i.sellingPrice-i.landed_cost)*i.qty)

        
    },0).toFixed(2),
    TotalQty:state=>state.items.reduce((t,i)=>t+i.qty,0),
    TotalSell:state=>state.items.reduce((t,i)=>t+(i.sellingPrice*i.qty),0).toFixed(2),
    TotalCost:state=>state.items.reduce((t,i)=>t+(i.landed_cost*i.qty),0).toFixed(2),
    TotalGPD:state=>state.items.reduce((t,i)=>t+((i.sellingPrice-i.landed_cost)*i.qty),0).toFixed(2),
    dump:state=>_.clone(state),
    TotalGPP:state=>
        state.items.length==0?0:
    
        ((state.items.reduce((t,i)=>t+((i.sellingPrice-i.landed_cost)*i.qty),0)/state.items.reduce((t,i)=>t+(i.sellingPrice*i.qty),0))*100).toFixed(2),
    getField
    
}

const mutations = {
    addItemToOrder(state,payload) {
        state.items.push(payload)
    },
    removeItemFromOrder(state,payload) {
        state.items=_.reject(state.items,i=>i.item_num==payload.item_num && i.um_selling==payload.um_selling)
    },
    clearOrder(state) {
        state.customerNum=null;
        state.shipTo=null;
        state.shipVia='BEST WAY';
        state.items=[];
        state.pmtMethod="House"
    },
    clearItems(state) {
        state.items=[];
    },
    UpdateItem(state,payload){
        let idx=_.findIndex(state.items,i=>i.item_num==payload.item_num && i.um_selling==payload.um)
        this._vm.$set(state.items,idx,_.extend(state.items[idx],payload))
      },
    updateField
}

const actions = {
    
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}