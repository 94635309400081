import axios from 'axios'
import _ from 'underscore'
import { getField, updateField } from 'vuex-map-fields';
import { msalInstance } from "vue-msal-browser"
import rg4js from 'raygun4js';

const state = {
    accessToken: null,
    idToken: null,
    expiresAt: null,
    tokensExpiry: null,
    msg:null,
    user:null,
    mainTokenResponse: null,
    commData:true,
    accountId:null,
    sortBy:"lastSoldDate",
    sortDesc:true,
    itemsPerPage:10,
    currentPage:1,
    prevSoldCheckbox:true
}

// const msalConfig = {
//     auth: {
//         clientId: "0de37472-045c-4874-b8fe-3b9aed13487e",
//         authority: "https://login.microsoftonline.com/d4829ae2-0059-43ae-9aa2-1505407d8934/"
//     },
//     cache: {
//         cacheLocation: "sessionStorage", // This configures where your cache will be stored
//         storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
//     },
//     system: {
//         loggerOptions: {
//             logLevel: msal.LogLevel.Trace,
//             loggerCallback: (level, message, containsPii) => {
//                 if (containsPii) {	
//                     return;	
//                 }
//                 switch (level) {	
//                     case msal.LogLevel.Error:	
//                         console.error(message);	
//                         return;	
//                     case msal.LogLevel.Info:	
//                         console.info(message);	
//                         return;	
//                     case msal.LogLevel.Verbose:	
//                         console.debug(message);	
//                         return;	
//                     case msal.LogLevel.Warning:	
//                         console.warn(message);	
//                         return;	
//                     default:
//                         console.log(message);
//                         return;
//                 }
//             }
//         }
//     },
//     telemetry: {
//         application: {
//             appName: "MSAL Browser V2 Default Sample",
//             appVersion: "1.0.0"
//         }
//     }
// };

// const myMSALObj = new msal.PublicClientApplication(msalConfig);

const getters = {
    isAuthenticated(state) {
        return state.idToken && Date.now()<new Date(state.expiresAt* 1000)
    },
    UserData:state=>state.user,
    AuthToken: state => state.accessToken,
    tokensExpiry:state=>new Date(state.expiresAt* 1000),
    showCommData: state=>state.commData,
    message: state=>state.msg,
    currentUser:state=>state.user.name,
    axiosHeaders:state=>({headers: {Authorization:`Bearer ${state.idToken}`}}),
    dump:state=>_.clone(state),
    getField
}

const mutations = {
    authenticated(state, authData) {
        state.accessToken = authData.accessToken
        state.idToken = authData.idToken
        state.expiresAt = authData.idTokenClaims.exp
        state.tokensExpiry=new Date(authData.expiresOn)
        state.user=_.omit(authData.account,'idToken','expiration')
        state.accountId=authData.homeAccountId

        // debugger
        rg4js('setUser', {
            identifier: authData.homeAccountId,
            isAnonymous: false,
            email: authData.account.username.toLowerCase(),
            fullName: authData.account.name,
          })
        // const tokensExpiry = addSeconds(new Date(), authData.expires_in || authData.expiresIn);
        // state.tokensExpiry = tokensExpiry;
        // localStorage.setItem('access_token', state.accessToken)
        // localStorage.setItem('id_token', state.idToken)
        // localStorage.setItem('expires_at', state.expiresAt)
    },
    
    logout(state) {
        state.accessToken = null
        state.idToken = false
        state.expiresAt = null
        state.tokensExpiry=null
        state.user=null
        state.accountId=null
        // localStorage.removeItem('access_token')
        // localStorage.removeItem('id_token')
        // localStorage.removeItem('expires_at')
    },
    updateField
}

const actions = {
    async signIn({ commit, getters, dispatch }) {
        try {
            let existingTokenResponse = getters.mainTokenResponse;
            let newTokenResponse;
      
            // The user has already logged in. We try to get his token silently
            if (existingTokenResponse)
              newTokenResponse = await msalInstance.acquireTokenSilent({
                account: existingTokenResponse.account,
                scopes: msalInstance.extendedConfiguration.auth.scopes,
              });
            // The user has not logged in. We check if he comes back from a redirect with a token
            // else newTokenResponse = await msalInstance.handleRedirectPromise();
      
            // No token found, we redirect the user
            if (!newTokenResponse) {
              
              let x=await msalInstance.loginPopup(msalInstance.loginRequest)
              
              //let x =await msalInstance.loginRedirect(msalInstance.loginRequest);

              commit('authenticated',x)
              return true;
            }
            // There is an existing token, we authentify the user
            else if (newTokenResponse) {
              // We add the access token as an authorization header for our Axios requests to our API
              commit('authenticated',newTokenResponse)
            //   commit('SET_TOKEN', newTokenResponse)
            //     commit('SET_AUTHENTICATED', true)
            //     commit('SET_NAME', newTokenResponse.idTokenClaims.given_name)
              //if (msalInstance.extendedConfiguration.graph) {
                // The graph is set, we check if the user has already a picture in the local storage
                // if he does not we grab a token silently for our graph scope and call Microsoft graph to get the picture
                // if (!localStorage.getItem("userPicture")) {
                //   let graphTokenResponse = await msalInstance.getSilentToken(
                //     newTokenResponse.account,
                //     msalInstance.extendedConfiguration.graph.scopes
                //   );
                //   let graphResponse = await msalInstance.callMSGraph(
                //     msalInstance.extendedConfiguration.graph.url,
                //     graphTokenResponse.accessToken
                //   );
                //   dispatch("AzureSetPicture", graphResponse);
                // }
              //}
              return true;
            }
          } catch (error) {
            console.error(error);
          }
    },

    signout({ commit,state }) {
        const logoutRequest = {
            account: msalInstance.getAccountByHomeId(state.accountId)
        };
        commit('logout')
        msalInstance.logoutRedirect(logoutRequest);
    },

    // refreshToken({commit}) {
    //     debugger
    //     myMSALObj.acquireTokenSilent({scopes:["user.read"],account:msal.account,forceRefresh:false})
    //   .then(r=>
    //     {
    //         commit('authenticated',r)
    //     })
    //   .catch(e=>{
    //     if (e instanceof InteractionRequiredAuthError) {
    //         // fallback to interaction when silent call fails
    //         msal.acquireTokenPopup({scopes:["user.read"],loginHint:msal.account.userName})
    //         .then(r=>commit('authenticated',r))
    //         .catch(error=>{
    //             rg4js('send',{error:error})
    //         })
    //     }
    //   });
    // }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}