import { connection } from "./jsstore_con";
import * as JsStore from "jsstore";


const DBName = "hocknet" + (process.env.NODE_ENV === 'production' ? '' : 'DEV')

//const DBName = `Hocknet`;
const getDatabase = (nm) => {
    var dName = DBName

    if (nm) dName += ('-' + nm)

    var arrTables = [
        {
            name: 'Customers',
            columns: {
                address1: { notNull: false, dataType: JsStore.DATA_TYPE.String },
                address2: { notNull: false, dataType: JsStore.DATA_TYPE.String },
                bAddress1: { notNull: false, dataType: JsStore.DATA_TYPE.String },
                bAddress2: { notNull: false, dataType: JsStore.DATA_TYPE.String },
                bCity: { notNull: false, dataType: JsStore.DATA_TYPE.String },
                bState: { notNull: false, dataType: JsStore.DATA_TYPE.String },
                bZip: { notNull: false, dataType: JsStore.DATA_TYPE.String },
                city: { notNull: false, dataType: JsStore.DATA_TYPE.String },
                customerName: { notNull: false, dataType: JsStore.DATA_TYPE.String },
                customerNum: { primaryKey: true, dataType: JsStore.DATA_TYPE.String },
                defaultBranch: { notNull: false, dataType: JsStore.DATA_TYPE.Number },
                shipTo: { notNull: false, dataType: JsStore.DATA_TYPE.String },
                shipToName: { notNull: false, dataType: JsStore.DATA_TYPE.String },
                shipVia: { notNull: false, dataType: JsStore.DATA_TYPE.String },
                state: { notNull: false, dataType: JsStore.DATA_TYPE.String },
                zipCode: { notNull: false, dataType: JsStore.DATA_TYPE.String },
            }
        },
        {
            name: 'CustomerOrders',
            columns: {
                docNum: { notNull: false, dataType: JsStore.DATA_TYPE.String },
                custNum: { notNull: false, dataType: JsStore.DATA_TYPE.String },
                //initWhse: { notNull: false, dataType: JsStore.DATA_TYPE.String },
                //salespersonTerr: { notNull: false, dataType: JsStore.DATA_TYPE.String },
                itemNum: { notNull: false, dataType: JsStore.DATA_TYPE.String },
                umSelling: { notNull: false, dataType: JsStore.DATA_TYPE.String },
                //amountOrdered: { notNull: false, dataType: JsStore.DATA_TYPE.Number },
                //amountCommitted: { notNull: false, dataType: JsStore.DATA_TYPE.Number },
                //amountBackordered: { notNull: false, dataType: JsStore.DATA_TYPE.Number },
                
                idx: { notNull: true, dataType: JsStore.DATA_TYPE.String, primaryKey: true }
            }
        },
        {
            name: 'Inventory',
            columns: {
                item_num: { notNull: false, dataType: JsStore.DATA_TYPE.String },
                description_1: { notNull: false, dataType: JsStore.DATA_TYPE.String },
                description_2: { notNull: false, dataType: JsStore.DATA_TYPE.String },
                um_selling: { notNull: false, dataType: JsStore.DATA_TYPE.String },
                std_price: { notNull: false, dataType: JsStore.DATA_TYPE.Number},
                comms:{notNull:false,dataType:JsStore.DATA_TYPE.Array},
                // mult1: { notNull: false, dataType: JsStore.DATA_TYPE.Number },
                // mult2: { notNull: false, dataType: JsStore.DATA_TYPE.Number },
                // mult3: { notNull: false, dataType: JsStore.DATA_TYPE.Number },
                // mult: { notNull: false, dataType: JsStore.DATA_TYPE.Number },
                // mult: { notNull: false, dataType: JsStore.DATA_TYPE.Number },
                // mult: { notNull: false, dataType: JsStore.DATA_TYPE.Number },
                // uM_PRICING: { notNull: false, dataType: JsStore.DATA_TYPE.String },
                // stD_PRICE: { notNull: false, dataType: JsStore.DATA_TYPE.Number },
                // STd_mult: { notNull: false, dataType: JsStore.DATA_TYPE.String },
                // std_BASIS:{ notNull: false, dataType: JsStore.DATA_TYPE.String },
                // stD_COMM_PCT:{ notNull: false, dataType: JsStore.DATA_TYPE.String },
                // warehouse:{ notNull: false, dataType: JsStore.DATA_TYPE.String },
                // idx: { notNull: true, dataType: JsStore.DATA_TYPE.String, primaryKey: true }
                on_hand: { notNull: false, dataType: JsStore.DATA_TYPE.Number},
                committed: { notNull: false, dataType: JsStore.DATA_TYPE.Number},
                on_order: { notNull: false, dataType: JsStore.DATA_TYPE.Number},
                lastUpdated:{dataType:JsStore.DATA_TYPE.DateTime},
                item_um:{keyPath:['item_num','um_selling'],},
                itemKey: { notNull: false, dataType: JsStore.DATA_TYPE.String, primaryKey: true },
            }
        },
        {
            name: 'LocalOrders',
            columns: {
                Id:{ primaryKey: true, autoIncrement: true },
                LocalId: { notNull: true, dataType: JsStore.DATA_TYPE.Number },
                custNum: { notNull: false, dataType: JsStore.DATA_TYPE.String },
            }
        },
        {
            name: 'ShipMethods',
            columns: {
                warehouse: { dataType: JsStore.DATA_TYPE.Number },
                shipMethod: { notNull: false, dataType: JsStore.DATA_TYPE.String },
            }
        },
        // {
        //     name: 'picks',
        //     columns: {
        //         id: { primaryKey: true, dataType: JsStore.DATA_TYPE.String },
        //         franchiseId: { notNull: false, dataType: JsStore.DATA_TYPE.String, enableSearch: true },
        //         companyId: { notNull: false, dataType: JsStore.DATA_TYPE.Number, enableSearch: true },
        //         orderBy: { notNull: false, dataType: JsStore.DATA_TYPE.Number },
        //         pickType: { notNull: false, dataType: JsStore.DATA_TYPE.String, enableSearch: true },
        //         countryId: { notNull: false, dataType: JsStore.DATA_TYPE.Number, enableSearch: true },
        //         franchisePickType: { keyPath: ['franchiseId', 'pickType'], enableSearch: true },
        //         companyPickType: { keyPath: ['companyId', 'pickType'], enableSearch: true },
        //     },
        //     version: DBVersion
        // },
        // {
        //     name: 'insp-photos',
        //     columns: {
        //         photoId: { primaryKey: true, dataType: JsStore.DATA_TYPE.String },
        //         inspectionId: { notNull: false, dataType: JsStore.DATA_TYPE.String, enableSearch: true },
        //         hashCode: { notNull: false, dataType: JsStore.DATA_TYPE.String, enableSearch: true },
        //         position: { notNull: false, dataType: JsStore.DATA_TYPE.Number },
        //         url: { notNull: false, dataType: JsStore.DATA_TYPE.String },
        //         data: { notNull: false, dataType: JsStore.DATA_TYPE.String }
        //     },
        //     version: DBVersion
        // },
        // {
        //     name: 'logos',
        //     columns: {
        //         photoId: { primaryKey: true, dataType: JsStore.DATA_TYPE.String },
        //         franchiseId: { notNull: false, dataType: JsStore.DATA_TYPE.String },
        //         hashCode: { notNull: false, dataType: JsStore.DATA_TYPE.String },
        //         position: { notNull: false, dataType: JsStore.DATA_TYPE.Number },
        //         url: { notNull: false, dataType: JsStore.DATA_TYPE.String },
        //         data: { notNull: false, dataType: JsStore.DATA_TYPE.String }
        //     },
        //     version: DBVersion
        // },
        // {
        //     name: 'LibraryPhotos',
        //     columns: {
        //         photoId: { primaryKey: true, dataType: JsStore.DATA_TYPE.String },
        //         inspectionId: { notNull: true, dataType: JsStore.DATA_TYPE.String },
        //         hashCode: { notNull: false, dataType: JsStore.DATA_TYPE.String },
        //         position: { notNull: false, dataType: JsStore.DATA_TYPE.Number },
        //         url: { notNull: false, dataType: JsStore.DATA_TYPE.String },
        //         data: { notNull: false, dataType: JsStore.DATA_TYPE.String }
        //     },
        //     version: DBVersion
        // },
        // {
        //     name: 'FreeForms',
        //     columns: {
        //         id: { primaryKey: true, dataType: JsStore.DATA_TYPE.String },
        //         parentId: { notNull: true, dataType: JsStore.DATA_TYPE.String },
        //         title: { notNull: false, dataType: JsStore.DATA_TYPE.String },
        //         orderBy: { notNull: false, dataType: JsStore.DATA_TYPE.Number },
        //         text: { notNull: false, dataType: JsStore.DATA_TYPE.String },
        //         originalId: { notNull: false, dataType: JsStore.DATA_TYPE.String }
        //     },
        //     version: DBVersion
        // },
        // {
        //     name: 'Contacts',
        //     columns: {
        //         inspectionClientId: { primaryKey: true, dataType: JsStore.DATA_TYPE.String },
        //         inspectionId: { notNull: true, dataType: JsStore.DATA_TYPE.String },
        //         email: { notNull: false, dataType: JsStore.DATA_TYPE.String },
        //         firstName: { notNull: false, dataType: JsStore.DATA_TYPE.String },
        //         LastName: { notNull: false, dataType: JsStore.DATA_TYPE.String },
        //         isPrimary: { notNull: false, dataType: JsStore.DATA_TYPE.Boolean },
        //         phoneNumber: { notNull: false, dataType: JsStore.DATA_TYPE.String },
        //         type: { notNull: false, dataType: JsStore.DATA_TYPE.Number },
        //         fullName: { notNull: false, dataType: JsStore.DATA_TYPE.String },
        //         extension: { notNull: false, dataType: JsStore.DATA_TYPE.String },

        //     },
        //     version: DBVersion
        // },
        // {
        //     name: 'InvoiceItems',
        //     columns: {
        //         invoiceItemId: { primaryKey: true, dataType: JsStore.DATA_TYPE.String },
        //         inspectionId: { notNull: true, dataType: JsStore.DATA_TYPE.String },
        //         amount: { notNull: false, dataType: JsStore.DATA_TYPE.Number },
        //         description: { notNull: false, dataType: JsStore.DATA_TYPE.String },
        //         discount: { notNull: false, dataType: JsStore.DATA_TYPE.Number },
        //         orderBy: { notNull: false, dataType: JsStore.DATA_TYPE.Number },
        //         subContractor: { notNull: false, dataType: JsStore.DATA_TYPE.String },
        //         subContractorFee: { notNull: false, dataType: JsStore.DATA_TYPE.Number },
        //         subContractorType: { notNull: false, dataType: JsStore.DATA_TYPE.String },

        //     },
        //     version: DBVersion
        // },
        // {
        //     name: 'Payments',
        //     columns: {
        //         paymentId: { primaryKey: true, dataType: JsStore.DATA_TYPE.String },
        //         inspectionId: { notNull: true, dataType: JsStore.DATA_TYPE.String },
        //         amount: { notNull: false, dataType: JsStore.DATA_TYPE.Number },
        //         method: { notNull: false, dataType: JsStore.DATA_TYPE.String },
        //         date: { notNull: false, dataType: JsStore.DATA_TYPE.String },
        //         orderBy: { notNull: false, dataType: JsStore.DATA_TYPE.Number }

        //     },
        //     version: DBVersion
        // },
    ]
    const dataBase = {
        name: dName,
        tables: arrTables,
        version: 19
    };
    return dataBase;
};


export const initJsStore = async (nm) => {
    
    var isDbCreated=await connection.initDb(getDatabase(nm));
    if (isDbCreated) {
        console.log('db created');
    }
    else {
        console.log('db opened');
    }

    return isDbCreated
};

export const dropJsStore = (nm) => {
    return connection.dropDb()
};