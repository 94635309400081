import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/index'
import Home from '../views/Home.vue'
// import msal from 'vue-msal'

// Vue.use(msal, {
//     auth: {
//       authority: 'https://login.microsoftonline.com/d4829ae2-0059-43ae-9aa2-1505407d8934/',
//     clientId: '0de37472-045c-4874-b8fe-3b9aed13487e',
//     },
//     graph: {
//       callAfterInit: true
//     }
// });
Vue.use(Router)

const router = new Router({
    base: __dirname,
    mode: 'history',
    routes: [
    //     {
    //     path: '/',
    //     name: 'Home',
    //     component: Home,

    // },
    {
        path: '/login',
        name: 'Login',
        component: ()=>import(/* webpackPrefetch: true */'@/views/Login.vue'),
        props:route => ({ redirect: route.query.redirect })

    },
    {
        path: '/',
        name: 'Order Entry',
        component: () => import(/* webpackPrefetch: true */'@/views/CommissionEst.vue'),
        meta: {
            requiresAuthentication: true
        },
        props: true
    },
    {
        path: '/signin-oidc',
        name: 'Azure Signed In',
        component: ()=>import(/* webpackPrefetch: true */'@/views/Auth.vue'),
        props: true

    },
    {
        path: '/Home',
        name: 'Home',
        component: () => import(/* webpackPrefetch: true */'@/views/Home.vue')
    },
    // {
    //     path: '/profile',
    //     name: 'User Profile',
    //     component: () => import(/* webpackPrefetch: true */'@/views/Profile.vue'),
    //     // meta: {
    //     //     requiresAuthentication: true
    //     // }
    // },
    {
        path: '/labels/:typ?',
        name: 'Labels',
        component: () => import(/* webpackPrefetch: true */'@/views/Labels.vue'),
        props: true
    }]
})

// router.beforeEach((to, from, next) => {
//     var acct=msal.getAccount();
//     if (to.matched.some(record => record.meta.requiresAuthentication) && !acct) {
//         // this route requires auth, check if logged in
//         //next({path:'/login',query:{redirect:to.name}})
//     } else {
//         next();
//     }
// });

export default router