import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      themes: {
         light: {
           primary: '#a12641',
        //   secondary: colors.grey.darken1,
        //   accent: colors.shades.black,
        //   error: colors.red.accent3,
        },
        dark: {
          primary: '#a12641',
        },
      },
    },
  });
