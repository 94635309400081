import Vue from 'vue'
import App from './App.vue'
import "./registerServiceWorker";

var rg4js = require("raygun4js");
import vuetify from './plugins/vuetify';
import axios from 'axios'
import VueAxios from 'vue-axios'
import underscore from './plugins/v-underscore.js';
import globalPlug from './plugins/global.js';
import store from './store'
import Snotify, { SnotifyPosition } from "vue-snotify";
import router from "./router";
import VueLuxon from "vue-luxon";

//import msal from 'vue-msal'
//import { MsalPlugin} from './plugins/msal-plugin';
import { default as msalPlugin } from "vue-msal-browser";

function SetToken(token) {
  window.App.$store.commit("User/authenticated" , token);
}

// const opt = {
//   clientId: '0de37472-045c-4874-b8fe-3b9aed13487e',
//   loginAuthority:  'https://login.microsoftonline.com/d4829ae2-0059-43ae-9aa2-1505407d8934/',
//   knownAuthority: 'login.microsoftonline.com'
// };

// Vue.use(new MsalPlugin(), opt);

Vue.use(VueLuxon,{output:{format:{
  month:"numeric",
  day:"numeric",
  year:"numeric"
}}});

Vue.use(msalPlugin, {
    auth: {
      authority: 'https://login.microsoftonline.com/d4829ae2-0059-43ae-9aa2-1505407d8934/',
    clientId: '0de37472-045c-4874-b8fe-3b9aed13487e',
    redirectUri: 'https://sales.hockenbergs.com',
    mode:"popup",
    // requireAuthOnInitialize:true,
    // onAuthentication:(ctx,err,res)=>{
    //   console.log('Authentication Results:')
    //   console.log(response);
    //   console.log(ctx);
    //   if(err) {
    //     debugger
    //     rg4js("send", {
    //       error: err,
    //       customData: [{ userState: {}}],
    //     });
    //   }
    //   debugger

    // },
    // onToken: (ctx, error, response) => {
    //   if(response.tokenType=="id_token" || response.idToken.rawIdToken) {
        
    //     // SetToken(response.idToken)
    //     window.App.$store.commit("User/authenticated" , response);
    //   } else {
    //     debugger
    //     console.log('Token Results:')
    //     console.log(response);
    //   console.log(ctx);
    //   console.log(error);
    //   }
    //   if(error) {
    //     debugger
    //     rg4js("send", {
    //       error: error,
    //       customData: [{ userState: {}}],
    //     });
    //   }

    // }
     },
    // request:{
    //   scopes:['api://0de37472-045c-4874-b8fe-3b9aed13487e/Users.Read']
    // },
    graph: {
      callAfterInit: true
    }
});

const options = {
  toast: {
    position: SnotifyPosition.leftBottom,
    timeout: 5000
  },
};

Vue.use(underscore)

Vue.use(Snotify, options);

Vue.config.productionTip = false
Vue.config.devtools = true

Vue.use(VueAxios, axios)

function setRayGunPageView(p) {
  if (rg4js == null) {
    setTimeout(setRayGunPageView, 500);
  } else {
    rg4js("trackEvent", {
      type: "pageView",
      path: p,
    });
  }
}

rg4js("enableCrashReporting", true);
rg4js("enablePulse", true);
rg4js("apiKey", "C8DCdr8WWVvWHa8cZBiooQ");
rg4js("options", {
  // debugMode:true,
  excludedHostnames: ["localhost", ".dev"/*, ".shackletons.net"*/],
  automaticPerformanceCustomTimings: true,
  ignore3rdPartyErrors: false,
  captureUnhandledRejections: true
});

rg4js('logContentsOfXhrCalls', true)
rg4js('saveIfOffline', true);
rg4js('setBreadcrumbLevel', 'info')

var v = "1560";
if (!new RegExp(/#\{.+\}#/).test(v)) SetRaygunVersion(`1.${v}.0`);

function SetRaygunVersion(version) {
  
  if (rg4js == null) {
    setTimeout(SetRaygunVersion, 800, version);
  } else {
    console.info("Version: " + version);
    rg4js("setVersion", version);
  }
}

router.afterEach((to, from) => {
  setRayGunPageView(to.fullPath);
});

Vue.use(globalPlug)

const app=new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')

window.App = app;
