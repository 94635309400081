/* eslint-disable */
var rg4js = require("raygun4js");
import _ from 'underscore'
import store from '@/store/index'

export default {
    install: function (app) {
        app.prototype.$raygun = rg4js;
        app.prototype.$sendError=function(err,data) {
            let cData=[{userState:this.$store.getters["User/dump"]},{orderState:this.$store.getters["Order/dump"]}];
            
            if(data!=null) {
                cData.push({errData:data})
            }

            rg4js("send", {
                error: err,
                customData: cData,
              });
        }

        app.config.errorHandler = function (err, vm, info) {
            if (
              err.name !== 'NavigationDuplicated' &&
              !err.message.includes('Avoided redundant navigation to current location')
            ) {
                let cData=[];

                try
                {
                    cData.push({userState:vm.$store.getters["User/dump"]})
                } catch(e){console.warn(e)}

                try
                {
                    cData.push({orderState:vm.$store.getters["Order/dump"]})
                } catch(e){console.warn(e)}
                
            if(info!=null) {
                cData.push({errData:info})
            }
              console.error(err, info);
              rg4js("send", {
                error: err,
                customData: cData,
              });
            }
          
          };
    }
};