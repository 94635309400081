import { connection } from "./jsstore_con";
import _ from 'underscore';

export class CommissionService {

    constructor() { }

    getCustomers() {
        return connection.select({
            from: 'Customers'
        })
    }

    addCustomers(val) {
        var v = val;
        if (!_.isArray(val)) v = [val];
        return connection.insert({
            into: 'Customers',
            values: v,
            upsert: true,
            return: true
        })
            .catch(err => console.error('Error Inserting Franchise', err))
    }

    addCustomerOrders(val) {
        var v = val;
        if (!_.isArray(val)) v = [val];

        v = v.map(i => {
            i["idx"] = i.docNum + '-' + i.custNum + '-' + i.itemNum + '-' + i.umSelling
            return i
        })
        return connection.insert({
            into: 'CustomerOrders',
            values: v,
            upsert: true,
            return: true
        })
            .catch(err => console.error('Error Inserting Franchise', err))
    }

    SaveInventory(val) {
        
        
            var v = val;
        if (!_.isArray(val)) v = [val];

        v = v.map(i => {

            i["comms"] = [{"mult":i.mult1,"pct":i.pcT_1,"s_price":(i.mult1*i.std_price)},
            {"mult":i.mult2,"pct":i.pcT_2,"s_price":(i.mult2*i.std_price)},
            {"mult":i.mult3,"pct":i.pcT_3,"s_price":(i.mult3*i.std_price)},
            {"mult":i.mult4,"pct":i.pcT_4,"s_price":(i.mult4*i.std_price)},
            {"mult":i.mult5,"pct":i.pcT_5,"s_price":(i.mult5*i.std_price)},
            {"mult":i.mult6,"pct":i.pcT_6,"s_price":(i.mult6*i.std_price)}]

            i["itemKey"]=i.item_num+'|'+i.um_selling
            i["lastUpdated"]=new Date(i.lastUpdated)
            return _.omit(i,(v,k,o)=>k.startsWith('mult') || k.startsWith('pcT'))
        })
        return connection.insert({
            into: 'Inventory',
            values: v,
            upsert: true,
            ignore:true,
            return: true
        })
            .catch(err => console.error('Error Inserting Inventory', err))
    }

    GetAllItemsByPage(cust,pg,pgSize,searchTxt,sBy,sDesc) {
        if(pgSize<5) {pgSize=1000000}
        return connection.select({
            from:"CustomerOrders",
            where:{custNum:cust}
        }).then(co=>{
            var orderData=co.map(o=>({itemNum:o.itemNum,umSelling:o.umSelling,lastSoldDate:o.orderDate,lastSoldPrice:o.lastSoldPrice}))
            return connection.select({
                from:'Inventory'
            }).then(o=>{
                o.forEach(i=>{
                    var ioData=_.find(orderData,d=>d.itemNum==i.item_num && d.umSelling==i.um_selling)
                    
                    if(ioData) {
                        i.lastSoldDate=ioData.lastSoldDate;
                        i.lastSoldPrice=ioData.lastSoldPrice;
                    }else {
                        i.lastSoldDate=0;
                    i.lastSoldPrice=0.0;
                    }
                })
                
                let tmp=_.sortBy(o,sBy||'description_1')
                if(sDesc) tmp.reverse();

                if(searchTxt) {
                    var re=new RegExp(searchTxt,'i')
                    
                    var items=_.filter(tmp,i=>{
                        return re.test(i.item_num) || re.test(i.description_1) || re.test(i.description_2)
                    })
    
                    return {items:_.chunk(items,pgSize)[Math.min(pg,items.length)],totalCount:items.length}
                }
    
                return {items:_.chunk(tmp,pgSize)[Math.min(pg,tmp.length)],totalCount:tmp.length}
            })
        })
        
    }

    GetLastInventoryStats() {
        return connection.select({
            from: "Inventory",
            aggregate:{
                max: 'lastUpdated',
                // You can specify multiple columns at a time by providing columns name in an array.
                count:['itemKey']
            }
        })
        
    }

    GetInventoryList(searchTxt,prevSold,cust) {
        var re=new RegExp(searchTxt,'i')
        
        if(prevSold && cust)
        {
            return connection.select({
                from:"Inventory",
                join:{
                    with:"CustomerOrders",
                    on:"Inventory.item_num=CustomerOrders.itemNum",
                    where:{custNum:cust}
                },
                where:{
                    description_1:{
                        regex:re
                    },
                    or:{
                        item_num:{
                            regex:re
                        }
                    }
                }
            })
            // .then(o=>{
            //     if(searchTxt) {
            //         var re=new RegExp(searchTxt,'i')
                    
            //         var items=_.filter(o,i=>{
            //             return re.test(i.item_num) || re.test(i.description_1) || re.test(i.description_2)
            //         })
    
            //         return items.length
            //     }
    
            //     return o.length
            // })
        } else {
            return connection.select({
            from: "Inventory",
            where:{
                description_1:{
                    regex:re
                },
                or:{
                    item_num:{
                        regex:re
                    }
                }
            }
        })
        }
        
        // .then(itms=>{
            
                    
        //             return _.filter(itms,i=>{
        //                 return re.test(i.item_num) || re.test(i.description_1) || re.test(i.description_2)
        //             })
        // })
        // .catch(err=>{
        //     debugger
        //     console.log(err)
        //     return []
        // })
    }

    GetItemCount(cust,prevSold,searchTxt) {
        var w={custNum:cust};

        if(prevSold) {
            return connection.select({
                from:"Inventory",
                join:{
                    with:"CustomerOrders",
                    on:"Inventory.item_num=CustomerOrders.itemNum",
                    where:{custNum:cust}
                }
            })
            .then(o=>{
                if(searchTxt) {
                    var re=new RegExp(searchTxt,'i')
                    
                    var items=_.filter(o,i=>{
                        return re.test(i.item_num) || re.test(i.description_1) || re.test(i.description_2)
                    })
    
                    return items.length
                }
    
                return o.length
            })
        } else {
            return connection.select({
                from:'Inventory'
            }).then(o=>{
                if(searchTxt) {
                    var re=new RegExp(searchTxt,'i')
                    
                    var items=_.filter(o,i=>{
                        return re.test(i.item_num) || re.test(i.description_1) || re.test(i.description_2)
                    })
    
                    return items.length
                }
    
                return o.length
            })
        }
    }

    GetItemsByPage(cust,pg,pgSize,sBy,sDesc,searchTxt) {
        if(pgSize<5) {pgSize=1000000}
        return connection.select({
            from:"CustomerOrders",
            where:{custNum:cust}
        }).then(co=>{
            var queryWhere=_.uniq(co.map(o=>o.itemNum+'||'+o.umSelling)).map(x=>x.split('||'))
            // .map(x=>{
            //     let parts=x.split('||')
            //     return {item_num:parts[0],um_selling:parts[1]}
            // })
            //console.log('Ordered Items',queryWhere)
            var orderData=co.map(o=>({itemNum:o.itemNum,umSelling:o.umSelling,lastSoldDate:o.orderDate,lastSoldPrice:o.lastSoldPrice}))
            
            return connection.select({
                from:"Inventory",
                where:{item_um:{
                    in: queryWhere
                }}
            })
            .then(o=>{
                
                if(o.length==0) {
                    return {items:[],totalCount:0}
                }
                let tCount=o.length
                
                    o.forEach(i=>{
                        var ioData=_.find(orderData,d=>d.itemNum==i.item_num && d.umSelling==i.um_selling)
                        i.lastSoldDate=ioData.lastSoldDate;
                        i.lastSoldPrice=ioData.lastSoldPrice;
                    })
                
                let tmp=_.sortBy(o,sBy||'description_1')
                
                if(sDesc) {
                    tmp.reverse()
                }
                let res=_.chunk(tmp,pgSize)
                if(searchTxt) {
                    var re=new RegExp(searchTxt,'i')
                    var items=_.filter(tmp,i=>{
                        return re.test(i.item_num) || re.test(i.description_1) || re.test(i.description_2)
                    })
                    tCount=items.length
                    res=_.chunk(items,pgSize)
                }
                
                pg=Math.min(pg,res.length);
                let rItems=res[pg];
                

                return {items:rItems,totalCount:tCount}
        })
            
            // return Promise.all(_.uniq(co.map(o=>[o.itemNum,o.umSelling])).map(x=>{
            //     return connection.select({
            //         from:"Inventory",
            //         where:{item_num:x[0]/*,um_selling:x[1]*/}
            //     })
            //     .then(o=>{
            //         if(o.length==0) {
            //             return []
            //         }
            //         if(searchTxt) {
            //             var re=new RegExp(searchTxt,'i')
                        
            //             return _.filter(o,i=>{
            //                 return re.test(i.item_num) || re.test(i.description_1) || re.test(i.description_2)
            //             })
            //         }
        
            //         return o
            // })
                
                
            // }))
            // .then(r=>{
            //     // console.log('Items',r)
            //     return _.chunk(_.flatten(r),pgSize)[Math.min(pg,_.flatten(r).length)]
            // })
            
        //     console.log('Ordered Items',queryWhere)
        //     return connection.select({
        //         from:"Inventory",
        //         where:queryWhere[0]
        //     })
        //     .then(o=>{
        //         if(searchTxt) {
        //             var re=new RegExp(searchTxt,'i')
                    
        //             var items=_.filter(o,i=>{
        //                 return re.test(i.item_num) || re.test(i.description_1) || re.test(i.description_2)
        //             })
    
        //             return _.chunk(items,pgSize)[Math.min(pg,items.length)]
        //         }
    
        //         return _.chunk(o,pgSize)[Math.min(pg,o.length)]
        // })
    })

        // return connection.select({
        //     from:"Inventory",
        //     join:{
        //         with:"CustomerOrders",
        //         on:"Inventory.item_num=CustomerOrders.itemNum",
        //         // where:{custNum:cust}
        //     }
        // })
        // .then(o=>{
        //     if(searchTxt) {
        //         var re=new RegExp(searchTxt,'i')
                
        //         var items=_.filter(o,i=>{
        //             return re.test(i.item_num) || re.test(i.description_1) || re.test(i.description_2)
        //         })

        //         return _.chunk(items,pgSize)[Math.min(pg,items.length)]
        //     }

        //     return _.chunk(o,pgSize)[Math.min(pg,o.length)]
            
            
        //     return connection.select({
        //         from:'Inventory',
        //         limit:pgSize,
        //         skip:pg*pgSize,
        //         where:{
        //             item_num: {
        //                 in:orderedItems
        //             }}
        //     }) 
        // })
        // .catch(err=>{
        //     debugger
        // })
    
    
    }

    addShipMethods(val) {
        var v = val;
        if (!_.isArray(val)) v = [val];
        return connection.insert({
            into: 'ShipMethods',
            values: v,
            upsert: true
        })
            .catch(err => console.error('Error Inserting ShipMethods', err))
    }

    getShipMethods() {
        return connection.select({
            from: 'ShipMethods'
        })
    }

    TruncateTable(tbl) {
        return connection.clear(tbl)
    }
}