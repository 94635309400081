<template>
  <v-container>
    <v-row v-if="user"
      ><v-col
        ><v-card
          ><v-card-title>Welcome {{user.name}}</v-card-title
          >
          <div v-if="user.profile.jobTitle">Your job title is {{user.profile.jobTitle}}</div>
          <div v-if="msal.idToken" v-text="msal.idToken"></div>
          <v-card-actions
            ><v-btn elevation="2" outlined @click="$msal.signOut()"
              >logout
            </v-btn></v-card-actions
          >
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else
      ><v-col
        ><v-card
          ><v-card-text
            >Please sign-in</v-card-text
          >
          <v-card-actions>
            <v-btn @click="$msal.signIn()">Signin</v-btn>
          </v-card-actions>
          </v-card
        ></v-col
      ></v-row
    >
  </v-container>
</template>

<script>
import { msalMixin } from 'vue-msal'; 
export default {
  name: "Home",
  mixins: [msalMixin],
  data() {
    return {};
  },
  computed: {
    user() {
          let user = null;
          if (this.msal.isAuthenticated) { // Note that the dollar sign ($) is missing from this.msal
            user = {
              ...this.msal.user,
              profile: {}
            }
            if (this.msal.graph && this.msal.graph.profile) {
                user.profile = this.msal.graph.profile
            }
          }
          return user;
        },
  },
  methods: {
    
  },
};
</script>